
/* eslint-disable no-undef,no-console, camelcase */
import { toast } from 'react-toastify';

import { http } from '../../http';
import { getRewardPoints } from '../index';
import * as actionTypes from '../actionTypes';
import {GetSocialPosts, GetCommentsOnPostById, AddCommentOnPost, AddInspirationPost, LikeUnlikePost, GetFriendRequests,
  AcceptAndRejectFriendRequest, GetActivityFeeds, AddCommentReplyOnPost, GetUserPosts, GetUserFriends, EditInspirationPost,
  DeleteInspirationPost, GetFeaturedWellnessExpert, RemoveUserFriend, GetUserPostImages, GetFriendDetails, GetCompanyExemplifiesValues,
  GetCompanyExemplifies, GetRecognitionIcons, DeleteCompanyExemplifyValues, AddCompanyExemplifiesValues, GetJoinedChallengesByUser,
  RemoveBuddy, GetJoinedEventByUser, GetListOfThingsToComplete, SuggestionsForYou,
  SuggestionFriendRequest, GetPostLikeDetails, UnFriendUser, GetAmigoBirthday, 
  UserBirthdayAndAnniversary, addBuddyOnPost, LikeUnlikePostComment, LikeUnlikeCommentReply, BirthdayAndAnniversaryStatus, UserWorkoutHistory,
  DayWiseWorkoutHistory, WorkoutActivityCount, WorkoutActivityByActivityType, GetMonthlyWorkoutStatus, SentRequestList, UpdateCompanyExemplifyValues, DeleteUserPostComment, GetInspirationPostById, GetAllMyAmigoInspirationPost,
  GetAnniversaryBirthdayUsers
} from '../../constants/apiConstants';

// import { FeedsLimitPerPage } from '../../../utils/constants';

const socialPostsData = (res, pageNumber, count) => (dispatch) => dispatch({ type: actionTypes.GET_SOCIAL_POSTS, response: res, pageNumber: pageNumber, count: count });

const myAmigoSocialPostsData = (res, pageNumber, count) => (dispatch) => dispatch({ type: actionTypes.GET_MY_AMIGO_SOCIAL_POSTS, amigoResponse: res, pageNumber: pageNumber, count: count });

const errorOccurred = (error) => (dispatch) => dispatch({ type: actionTypes.FAILED_GET_SOCIAL_POSTS, error });

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

let feedCount = 10
const getSocialPosts = (filterType, limit, pageNumber) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetSocialPosts}/${filterType}/${limit}/${pageNumber}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res && res.length >= 10){
        feedCount = feedCount + res.length
      }
      if(res.message) {
        // toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch(socialPostsData({response: res}, pageNumber, feedCount));
      }
    } catch(error) {
      // toast.error("failed api call");
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getCommentsOnPost = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SOCIAL_LOADING});
    try {
      const res = await http.get(`${GetCommentsOnPostById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch({type: actionTypes.FAILED_GET_COMMENTS_ON_POST, commentError: res.message});
      } else {
        dispatch({type: actionTypes.GET_COMMENTS_ON_POST, comments: res, id:id});
        // dispatch(getSocialPosts(socialPostData.filterType, socialPostData.limit, socialPostData.pageNumber))

      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getInspirationPost = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SOCIAL_LOADING});
    try {
      const res = await http.get(`${GetInspirationPostById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.GET_INSPIRATION_POST_BY_ID, inspirationPost: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const updatePost = (id, value, updationList) => (dispatch) => dispatch({type: actionTypes.UPDATE_POST, id: id, value: value, updationList: updationList});

const addCommentOnPost = (commentData, updationList) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(AddCommentOnPost, commentData, config);
      if(res.message) {
        dispatch({type: actionTypes.FAILED_ADD_COMMENT_ON_POST, postCommentError: res.message});
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.ADD_COMMENT_ON_POST, success: res[0]});
        dispatch(updatePost(commentData.post_id, 'comment', updationList));
        dispatch(getCommentsOnPost(commentData.post_id));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const birthdayAndAnniversaryStatus = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(BirthdayAndAnniversaryStatus, data, config);
      if(res.message) {
        dispatch({type: actionTypes.FAILED_ADD_COMMENT_ON_POST, postCommentError: res.message});
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        // dispatch({type: actionTypes.BIRTHDAY_STATUS, success: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};


const addSocialInspiration = (inspirationData, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const postData = {
    ...inspirationData,
    title: ''
  };
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    dispatch({type: actionTypes.SOCIAL_LOADING});
    try {
      const res = await http.post(AddInspirationPost, postData, config);
      setTimeout(() => {
        if(!res) {
          toast.info("We'll let you know once your post is ready");
        }
      }, 2000);
      if(res.message) {
        dispatch({type: actionTypes.FAILED_ADD_SOCIAL_INSPIRATION, inspirationError: res.message});
        toast.error(res.message);
      } else {
        dispatch(getSocialPosts('Everybody', limit,1));
        toast.success(res ? res[0] : '');
        dispatch({type: actionTypes.ADD_SOCIAL_INSPIRATION, success: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const likeUnlikePost = (data, updationList) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(LikeUnlikePost, data, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_LIKE_UNLIKE_POST, likeUnlikeError: res.message});
      } else {
        dispatch({type: actionTypes.LIKE_UNLIKE_POST, successLikeOrUnlike: res[0]});
        dispatch(updatePost(data.post_id, data.flag, updationList));
        dispatch(enableButton());
        dispatch(getPostLikeDetails(data.post_id));
        dispatch(getSocialPosts('Company',10, 1));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const disableButton = () => (dispatch) => dispatch({type: actionTypes.DISABLE_BUTTON});

const enableButton = () => (dispatch) => dispatch({type: actionTypes.ENABLE_BUTTON});

const getFriendRequests = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.get(GetFriendRequests, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_FRIEND_REQUESTS, requestsError: res.message});
      } else {
        dispatch({type: actionTypes.GET_FRIEND_REQUESTS, friendRequests: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const acceptOrRejectFriendRequest = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch, getState) => {
    try {
      const res = await http.post(AcceptAndRejectFriendRequest, data, config);
      if(res.message) {
        toast.error(res.message);
        dispatch(getFriendRequests());
        dispatch({type: actionTypes.FAILED_ACCEPT_REJECT_FRIEND_REQUEST, acceptRequestError: res.message});
      } else {
        if(data.status === 'Accepted') {
          dispatch(getRewardPoints());
        }
        toast.success(res[0]);
        dispatch(getFriendRequests());
        dispatch({type: actionTypes.ACCEPT_REJECT_FRIEND_REQUEST, requestAccepted: res[0]});
      }
      dispatch(getUserFriends(getState().profileData.user.uid));
      dispatch(getSentRequestList());
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getActivityFeeds = (pageNumber, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetActivityFeeds}/${pageNumber}/${limit}`, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_ACTIVITY_FEEDS, activityFeedsError: res.message});
      } else {
        dispatch({type: actionTypes.GET_ACTIVITY_FEEDS, activityFeeds: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const addCommentReplyOnPost = (commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const data = {
      comment_id: commentId,
      reply: reply,
      shoutout: shoutout,
      shoutout_user_name: shoutOutUserName,
      exemplify_id: exemplify_id,
      image: image
    };
    try {
      const res = await http.post(AddCommentReplyOnPost, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_ADD_COMMENT_REPLY_ON_POST, replyError: res.message});
      } else {
        dispatch({type: actionTypes.ADD_COMMENT_REPLY_ON_POST, replySuccess: res[0]});
        dispatch(getCommentsOnPost(postId));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getUserPosts = (id, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserPosts}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        history.push('/profile');
        dispatch({type: actionTypes.FAILED_GET_USER_POSTS, postsError: res.message});
      } else {
        dispatch(getFriendProfile(id))
        dispatch({type: actionTypes.GET_USER_POSTS, userPosts: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getUserPostImages = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserPostImages}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_USER_POST_IMAGES, imagesError: res.message});
      } else {
        dispatch({type: actionTypes.GET_USER_POST_IMAGES, userPostImages: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getUserFriends = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetUserFriends}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_USER_FRIENDS_LIST, friendListError: res.message});
      } else {
        dispatch({type: actionTypes.GET_USER_FRIENDS_LIST, friendList: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getWellnessExpert = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetFeaturedWellnessExpert, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_WELLNESS_EXPERT, wellnessExpertError: res.message});
      } else {
        dispatch({type: actionTypes.GET_WELLNESS_EXPERT, wellnessExpert: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const updateEditPosts = (id, value, updationList) => (dispatch) => dispatch({type: actionTypes.UPDATE_EDIT_POSTS, id, value, updationList});

const editSocialInspiration = (postDetails, updationList) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.put(EditInspirationPost, {...postDetails}, config);
      if(res.message) {
        dispatch({type: actionTypes.FAILED_ADD_SOCIAL_INSPIRATION, inspirationError: res.message});
        // toast.error(res.message);
      } else {
        dispatch(updateEditPosts(postDetails.id, postDetails, updationList));
        toast.success(res[0]);
      }
    } catch(error) {
      // toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const updateList = (id, updationList) => (dispatch) => dispatch({type: actionTypes.UPDATE_LIST, id, updationList});

const deleteSocialInspiration = (id, updationList, limit) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteInspirationPost}/${id}`, config);
      if(res.message) {
        dispatch({type: actionTypes.FAILED_ADD_SOCIAL_INSPIRATION, inspirationError: res.message});
        toast.error(res.message);
      } else {
        dispatch(updateList(id, updationList));
        toast.success(res[0]);
        dispatch(getSocialPosts('Everybody', limit,1));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const deleteCommentOnPost = (id, postId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteUserPostComment}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getCommentsOnPost(postId))
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const removeUserFriend = (friendId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${RemoveUserFriend}/${friendId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_REMOVE_FRIEND, removeFriendError: res.message});
      } else {
        dispatch({type: actionTypes.REMOVE_FRIEND, removeSuccess: res[0], friendId: friendId});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
};

const getFriendProfile = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetFriendDetails}/${id}`, {headers: { AUTHTOKEN: AuthToken } });
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.OTHER_USER_DETAIL, otherDetails:res[0]});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}
const getCompanyExemplifiesValues = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetCompanyExemplifiesValues, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_COMPANY_EXEMPLIFIES_VALUES, exemplifiesValues: res})
      }
    } catch(error) {
      toast.error(error.message);
    }
  }
}

const getCompanyExemplifies = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetCompanyExemplifies}/${id}` , {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_COMPANY_EXEMPLIFIES, companyExemplifiesValues: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      // window.location.reload();
    }
  }
}

const updateCompanyExemplifyValues = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.put(UpdateCompanyExemplifyValues , data,{headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        toast.success(res?.recognition);
        dispatch(getCompanyExemplifies(data?.company_id));
        dispatch({ type: actionTypes.UPDATE_COMPANY_EXEMPLIFY_VALUES, updateExemplifiesValues: res})
        // window.location.reload();
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
}


const addCompanyExemplifiesValues = (data, companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(AddCompanyExemplifiesValues, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_ADD_COMPANY_EXEMPLIFIES_VALUES, errorToAddExemplifiesValue: res.message});
      } else {
        toast.success(res[0]);
        dispatch(getCompanyExemplifies(companyID));
        dispatch({type: actionTypes.ADD_COMPANY_EXEMPLIFIES_VALUES, successAddedEXemplifiesValue: res[0]});
        // history.push('/company/recognition');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getRecognitionIcons = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetRecognitionIcons, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.GET_RECOGNITIONS_ICONS, recognitionIcons: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const deleteCompanyExemplifyValues = (id, companyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.delete(`${DeleteCompanyExemplifyValues}/${id}`, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.DELETE_COMPANY_EXEMPLIFIES_VALUES, deletedRecognition: res});
        toast.success(res[0]);
        dispatch(getCompanyExemplifies(companyID));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};
const getSuggestionsList = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(SuggestionsForYou, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        let response = []
        if(res && res[0] && typeof(res[0]) === "object" ){
          for (let obj in res[0]){
            response[response.length] = res[0][obj]
          }
          res[0] = response
        }
        dispatch({ type: actionTypes.GET_SUGGESTION_LIST, suggestionList: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const sendSuggestionFriendRequest = (data, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(SuggestionFriendRequest, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        toast.success(res[0]);
        dispatch(getSuggestionsList());
        dispatch({type: actionTypes.SENT_SUGGESTION_FRIEND_REQUEST, sentRequest: res[0]});
        history.push('/social');
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const getJoinedChallengesByUser = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetJoinedChallengesByUser, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_CHALLENGES_LIST, challengesList: res});
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getJoinedEventByUser = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetJoinedEventByUser, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_EVENT_LIST, eventList: res});
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getListOfThingsToComplete = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(GetListOfThingsToComplete, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_RECOMMENDED_LIST_STATUS, recommendedListStatus: res});
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getPostLikeDetails = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${GetPostLikeDetails}/${data}/post`, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_LIKE_DETAILS, postLikeDetails: res});
      }
    }
    catch(error) {
      // toast.error(error.message);
    }
  }
};

const unFriendUser = (data, bool) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${UnFriendUser}/${data}`, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_UNFRIEND_USER, unfriendUser: res});
        if(bool){
          window.location.reload();
        }
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const addBuddy = (buddyID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${addBuddyOnPost}/${buddyID}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.ADD_BUDDY_ON_POST, buddyDetails: res})
        dispatch(getFriendRequests());
        dispatch(getSuggestionsList())
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const sendRequest = (data) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(sendFriendRequest, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({ type: actionTypes.SEND_BUDDY_REQUEST, sendRequest: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const removeBuddy = (userID) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${RemoveBuddy}/${userID}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        toast.success(res[0]);
        dispatch({ type: actionTypes.REMOVE_BUDDY, removeBuddy: res[0]});
        dispatch(getSuggestionsList())
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      dispatch(getSuggestionsList())
    }
  }
}

const getAmigoBirthday = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${GetAmigoBirthday}`, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_BIRTHDAY_LIST, birthdayList: res});
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};

const getWorkoutHistory = (date) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${UserWorkoutHistory}/${date}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.GET_WORKOUT_HISTORY, workoutHistory: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getDailyWorkoutHistory = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(DayWiseWorkoutHistory, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.GET_DAY_WISE_WORKOUT_HISTORY, dayWiseWorkoutHistory: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getWorkoutActivityCount = (year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${WorkoutActivityCount}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.GET_WORKOUT_ACTIVITY_COUNT, workoutActivityCount: res})
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};
const userBirthdayAndAnniversary = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    const headers = {
      AUTHTOKEN: AuthToken
    };
    try {
      const res = await http.get(`${UserBirthdayAndAnniversary}`, { headers: headers });
      if(res.message){
        toast.error(res.message)
      }else{
        dispatch({type: actionTypes.GET_BIRTHDAY_ANNIVERSARY_WISH_STATUS, birthdayAnniversaryWishStatus: res});
      }
    }
    catch(error) {
      toast.error(error.message);
    }
  }
};
const managePostLikedUsers = (response) => (dispatch) => dispatch({ type: actionTypes.SET_FEEDS_DATA_GOBALLY, updatedFeedsData: response});

const likeUnlikePostComment = (data, postId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(LikeUnlikePostComment, data, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_LIKE_UNLIKE_POST, likeUnlikeError: res.message});
      } else {
        dispatch({type: actionTypes.LIKE_UNLIKE_POST, successLikeOrUnlike: res[0]});
        dispatch(enableButton());
        dispatch(getCommentsOnPost(postId));
        // dispatch(getSocialPosts('Company', 1))
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getworkoutActivityByActivityType = (activityType, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${WorkoutActivityByActivityType}/${activityType}/${year}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
        dispatch(errorOccurred(res.message));
      } else {
        dispatch({type: actionTypes.GET_WORKOUT_ACTIVITY_BY_TYPES, workoutActivityBytypes: res});
      }
    }
    catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const likeUnlikeCommentReply = (data, postId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.post(LikeUnlikeCommentReply, data, config);
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_LIKE_UNLIKE_POST, likeUnlikeError: res.message});
      } else {
        dispatch({type: actionTypes.LIKE_UNLIKE_POST, successLikeOrUnlike: res[0]});
        dispatch(enableButton());
        dispatch(getCommentsOnPost(postId));
        // dispatch(getSocialPosts('Company', 1))
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getMonthlyWorkoutStatus = (startDate, endDate) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetMonthlyWorkoutStatus}/${startDate}/${endDate}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_MONTHLY_ACTIVITY_STATUS, monthlyWorkoutStatus: res})
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getSentRequestList = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.get(SentRequestList, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_SEND_FRIEND_REQUESTS, sendRequestsData: res[0]});
      }
    } catch(error) {
      dispatch(networkError(error.message));
    }
  }
};

let responseCount = 10;
const getAllAmigoInspirationPost = (post_type, limit, pageNumber, uid) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetAllMyAmigoInspirationPost}/${post_type}/${limit}/${pageNumber}/${uid}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.length === 10){
        responseCount = responseCount + res.length
      }
       
      if(res.message) {
        toast.error(res.message);
        dispatch({type: actionTypes.FAILED_GET_ALL_MY_AMIGO_INSPIRATION_POST, userFeedError: res.message});
      } else {
        // dispatch({type: actionTypes.GET_ALL_MY_AMIGO_INSPIRATION_POST, userFeedSuccess: res});
        dispatch(myAmigoSocialPostsData({response: res}, pageNumber, responseCount));
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      window.location.reload();
    }
  }
};

const fetchAnniversaryBirthdayUsers = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  const config = {
    headers: {
      AUTHTOKEN: AuthToken
    }
  };
  return async (dispatch) => {
    try {
      const res = await http.get(GetAnniversaryBirthdayUsers, config);
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({type: actionTypes.GET_ANNIVERSARY_BIRTHDAY, anniversaryBirthdayUsersData: res});
      }
    } catch(error) {
      dispatch(networkError(error.message));
    }
  }
};

export { getSocialPosts, getCommentsOnPost, addCommentOnPost, addSocialInspiration, likeUnlikePost, getFriendRequests,
  acceptOrRejectFriendRequest, networkError, getActivityFeeds, disableButton, addCommentReplyOnPost, getUserPosts,
  getUserFriends, editSocialInspiration, deleteSocialInspiration, getWellnessExpert, removeUserFriend, getUserPostImages,
  getCompanyExemplifiesValues, getCompanyExemplifies, addCompanyExemplifiesValues, getRecognitionIcons, deleteCompanyExemplifyValues,
  getJoinedChallengesByUser, getJoinedEventByUser, getListOfThingsToComplete, sendSuggestionFriendRequest, getSuggestionsList, getPostLikeDetails, unFriendUser, addBuddy, sendRequest, removeBuddy
  ,getAmigoBirthday, userBirthdayAndAnniversary, managePostLikedUsers, likeUnlikeCommentReply, likeUnlikePostComment, birthdayAndAnniversaryStatus,
  getWorkoutHistory, getDailyWorkoutHistory, getWorkoutActivityCount, getworkoutActivityByActivityType, getMonthlyWorkoutStatus, getSentRequestList, updateCompanyExemplifyValues, deleteCommentOnPost, getInspirationPost, getAllAmigoInspirationPost, myAmigoSocialPostsData,
  fetchAnniversaryBirthdayUsers
};
